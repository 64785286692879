import "./Information.css";
import CustomIcon from "../../../../../components/Icon";
import FieldGroup from "../../../../../components/FieldGroup";
import TextField from "../../../../../components/TextField";
import CustomButton from "../../../../../components/Button/CustomButton"
import { useEffect, useRef, useState } from "react";
import {
  renderInfoSkeleton
  , renderPermissionSkeleton
  , renderOwnershipSkeleton
  , renderAttributesSkeleton
} from "./InformationRenderer";
import { useHistory, useParams } from "react-router";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import SelectBox from "../../../../../components/Select";
import { tSuccess, tWarnning } from "../../../../../common/CommonToast";
import ListTable from "../../../../../components/Table/ListTable";
import YamlEditor from "@focus-reactive/react-yaml";
import { oneDark } from "@codemirror/theme-one-dark";
import axiosService from "../../../../../api/axiosService";
import { baseUrl } from "../../../../../redux/contants/urlApi";

const Information = ({ }) => {
  const themeColor = process.env?.REACT_APP_THEME;
  const configRef = useRef();
  const { id } = useParams();
  const [data, setData] = useState({configData:`OPENQASM 2.0;
  include "qelib1.inc";
  
  qreg q[5];
  creg m0[1];  
  
  cx q[0],q[2];
  
  h q[0];
  z q[1];
  
  z q[2];
  
  rz(pi*1.0) q[1];
  rx(pi*0.25) q[3];
  ry(pi*3.1415926536) q[4];
  
  rz(pi*3.1415926536) q[3];
  ry(pi*1.0) q[4];
  
  ch q[2],q[1];
  cx q[2],q[3];
  
  measure q[2] -> m477[0];`
  ,instrumentControllerInfo:[]
  ,measurementControllerInfo:[]});
  const [loading, setLoading] = useState(true)
  const [qasmPop, setQasmPop] = useState({open: false})
  const [stationPop, setStationPop] = useState({open: false})
  const [instrumentControllerPop, setInstrumentControllerPop] = useState({open: false})
  const [measurementControllerPop, setMeasurementControllerPop] = useState({open: false})
  const history = useHistory();

  useEffect(() => {
    console.log("data : ", data)
  }, [data])

  useEffect(()=>{
    handleFetchDetail()
  },[])

  const handleFetchDetail = async () => {
    let res = await axiosService.get(
      baseUrl + "v1/stations/" + id + "/detail"
    );
    setLoading(false)
    if (res?.status == 200) {
      setData((prev) => ({
        ...prev,
        stationInfo: [
          { key: "ID", value: res?.data?.id }
          , { key: "Name", value: res?.data?.name }
          , { key: "Description", value: res?.data?.description }
          , { key: "State", value: 'ACTIVE' }
          , { key: "Resonator Type", value: 'M' }
          , { key: "LCM State", value: 'LCM_INIT' }
          , { key: "Run State", value: 'RUNNING' }
          , { key: "Started at", value: '10:39:32 30/12/2023' }
          , { key: "Finished at", value: '-' }
          , { key: "Deploy ID", value: '-' }
          , { key: "Reschedule", value: 'no' }
          , { key: "Virtual Router", value: '-' }
        ],
        instrumentControllerInfo: [
          { key: "ID", value: '27' }
          , { key: "Name", value: 'CES Instrument' }
          , { key: "Address", value: '192.168.26.55' }
          , { key: "Port", value: '5901' }
        ],
        measurementControllerInfo: [
          { key: "ID", value: '219' }
          , { key: "Name", value: 'CES Measurement' }
          , { key: "Address", value: '192.168.26.129' }
          , { key: "Port", value: '2704' }
        ],
      }));
    }
  }

  const infoRenderManager = () => {
    return (
      <>
        {data?.stationInfo?.map(item => { return renderInfoDiv(item.key, item.value, null) })}
      </>
    )
  }


  const instrumentControllerRenderManager = () => {
    return (
      <>
        {data?.instrumentControllerInfo?.map(item => { return renderInfoDiv(item.key, item.value, null) })}
      </>
    )
  }

  const measurementControllerRenderManager = () => {
    return (
      <>
        {data?.measurementControllerInfo?.map(item => { return renderInfoDiv(item.key, item.value, null) })}
      </>
    )
  }

  const renderInfoDiv = (key, value, icon) => {
    return (
      <div className={`station-info-page-left-grid-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
        <div>{key}</div>
        <div>{value}</div>
        <div className={`station-info-page-left-grid-icon`}>{icon}</div>
      </div>
    )
  }

  const handleStationUpdate = () => {
    // console.log("stationName : ",document.getElementById("stationName")?.value);
    // console.log("stationDescription : ",document.getElementById("stationDescription")?.value);
    // console.log("stationResonatorType : ",document.getElementById("stationResonatorType")?.value);
    let tempData = data?.stationInfo;
    tempData.find(item=>item.key==='Name').value = document.getElementById("stationName")?.value
    tempData.find(item=>item.key==='Description').value = document.getElementById("stationDescription")?.value
    tempData.find(item=>item.key==='Resonator Type').value = document.getElementById("stationResonatorType")?.value
    setData((prev)=>({...prev, stationInfo: tempData}))
    setStationPop((prev)=>({...prev, open:false}))
    tSuccess("Update Success")
  }

  const StationDialog = (props) => {
    const { onClose, open } = props;

    const handleClose = () => {
      onClose();
    };

    return (
      <Dialog onClose={handleClose} open={open} className="station-pop-container">
        <DialogTitle className="station-pop-title">Update Station Info</DialogTitle>
        <div className="station-pop-contents">
          <TextField id="stationName" label={"Name"} handleChange={()=>{}} defaultValue={data?.stationInfo?.find(item => item.key === "Name")?.value} />
          <TextField id="stationDescription" label={"Description"} handleChange={()=>{}} defaultValue={data?.stationInfo?.find(item => item.key === "Description")?.value} />
          <SelectBox id="stationResonatorType" label={"Resonator Type"} data={selectData} handleChange={()=>{}} defaultValue={"M"} />

          <div className="station-pop-btn-container">
            <div className="station-pop-right-btn">
              <CustomButton type="custom" label={"Cancel"} className="custom-btn-cancel" onClick={() => { setStationPop((prev) => ({ ...prev, open: false })) }} />
              <CustomButton type="custom" label={"Save"} onClick={() => { handleStationUpdate() }} />
            </div>
          </div>
        </div>
      </Dialog>
    );
  }


  const InstrumentControllerDialog = (props) => {
    const { onClose, open } = props;
    const [ctrlData, setCtrlData] = useState({});

    const handleClose = () => {
      onClose();
    };

    useEffect(() => {
      // 1. List 목록 받아오기.
      fetchDataList();

    }, [])

    const fetchDataList = async () => {
        let tempData = [
            {
                id: 1
                , name: "CES Controller"
                , description: "description"
                , address: '192.168.27.55'
                , port: '5901'
                , instrumentsType: "Controller"
                , manufacturer: 'Dummy Instrument'
            },
            {
                id: 2
                , name: "Test Dummy Instruments"
                , description: "description"
                , address: '0.0.0.0'
                , port: 'any'
                , instrumentsType: "Dummy Instruments"
                , manufacturer: 'Zurich'
            },
            {
                id: 3
                , name: "Test Oscilator1"
                , description: "description"
                , address: '52.79.142.243'
                , port: '6904'
                , instrumentsType: "Oscilator"
                , manufacturer: 'Qblox'
            },
        ]
        setCtrlData((prev) => ({ ...prev, list: tempData }))
    }

    const createListColumns = () => {
        return (
            [
                { id: 'id', label: 'ID', minWidth: 100, hidden: true },
                { id: 'name', label: 'Name', minWidth: 70 },
                { id: 'description', label: 'Description', minWidth: 200 },
                { id: 'address', label: 'Address', minWidth: 100 },
                { id: 'port', label: 'Port', minWidth: 100 },
                {
                    id: 'Type',
                    label: 'Insruments Type',
                    minWidth: 170,
                    align: 'left',
                    format: (value) => value.toLocaleString('en-US'),
                },
                { id: 'manufacturer', label: 'Manufacturer', minWidth: 100 },
            ]
        )
    }

    // Select Event
    const selectRows = (selectedRowData) => {
        setCtrlData((prev)=>({...prev, selectedRowData: selectedRowData}))
    }

    const handleSubmit = () => {
      
      if(!ctrlData?.selectedRowData){
        tWarnning("Please select Row.")
        return
      }

      let tempData = [
        {key:"ID", value:ctrlData?.selectedRowData?.id}
        ,{key:"Name", value:ctrlData?.selectedRowData?.name}
        ,{key:"Description", value:ctrlData?.selectedRowData?.description}
        ,{key:"Address", value:ctrlData?.selectedRowData?.address}
        ,{key:"Port", value:ctrlData?.selectedRowData?.port}
      ]

      setData((prev)=>({...prev, instrumentControllerInfo:tempData}))
      setInstrumentControllerPop((prev)=>({...prev, open:false}))
      tSuccess("Update Success")
    }

    return (
      <Dialog onClose={handleClose} open={open} className="controller-pop-container">
        <DialogTitle className="station-pop-title">Select Instrument Controller</DialogTitle>
        <div className="controller-pop-contents-container">
          <ListTable title={"Instrument Controller"} cols={createListColumns()} selectCallback={selectRows} list={ctrlData?.list} height={200} />

          <div className="station-pop-btn-container">
            <div className="station-pop-right-btn">
              <CustomButton type="custom" label={"Cancel"} className="custom-btn-cancel" onClick={() => { setInstrumentControllerPop((prev) => ({ ...prev, open: false })) }} />
              <CustomButton type="custom" label={"Save"} onClick={() => {handleSubmit()}} />
            </div>
          </div>
        </div>
      </Dialog>
    );
  }


  const MeasurementControllerDialog = (props) => {
    const { onClose, open } = props;
    const [ctrlData, setCtrlData] = useState({});

    const handleClose = () => {
      onClose();
    };

    useEffect(() => {
      // 1. List 목록 받아오기.
      fetchDataList();
    }, [])

    const fetchDataList = async () => {
        let tempData = [
            {
                id: 1
                , name: "CES Mesurement"
                , description: "description"
                , address: '192.168.27.129'
                , port: '2704'
                , instrumentsType: "Mesurement"
                , manufacturer: 'Dummy Mesurement'
            },
            {
                id: 2
                , name: "Test Dummy Mesurement"
                , description: "description"
                , address: '0.0.0.0'
                , port: 'any'
                , instrumentsType: "Dummy Mesurement"
                , manufacturer: 'Zurich'
            },
            {
                id: 3
                , name: "Test Mesurement"
                , description: "description"
                , address: '52.79.142.243'
                , port: '6904'
                , instrumentsType: "Mesurement"
                , manufacturer: 'Qblox'
            },
        ]
        setCtrlData((prev) => ({ ...prev, list: tempData }))
    }

    const createListColumns = () => {
        return (
            [
                { id: 'id', label: 'ID', minWidth: 100, hidden: true },
                { id: 'name', label: 'Name', minWidth: 70 },
                { id: 'description', label: 'Description', minWidth: 200 },
                { id: 'address', label: 'Address', minWidth: 100 },
                { id: 'port', label: 'Port', minWidth: 100 },
                { id: 'manufacturer', label: 'Manufacturer', minWidth: 100 },
            ]
        )
    }

    // Select Event
    const selectRows = (selectedRowData) => {
        setCtrlData((prev)=>({...prev, selectedRowData: selectedRowData}))
    }

    const handleSubmit = () => {
      
      if(!ctrlData?.selectedRowData){
        tWarnning("Please select Row.")
        return
      }

      let tempData = [
        {key:"ID", value:ctrlData?.selectedRowData?.id}
        ,{key:"Name", value:ctrlData?.selectedRowData?.name}
        ,{key:"Description", value:ctrlData?.selectedRowData?.description}
        ,{key:"Address", value:ctrlData?.selectedRowData?.address}
        ,{key:"Port", value:ctrlData?.selectedRowData?.port}
      ]

      setData((prev)=>({...prev, measurementControllerInfo:tempData}))
      setMeasurementControllerPop((prev)=>({...prev, open:false}))
      tSuccess("Update Success")
    }

    return (
      <Dialog onClose={handleClose} open={open} className="controller-pop-container">
        <DialogTitle className="station-pop-title">Select Measurement Controller</DialogTitle>
        <div className="controller-pop-contents-container">
          <ListTable title={"Measurement Controller"} cols={createListColumns()} selectCallback={selectRows} list={ctrlData?.list} height={200} />

          <div className="station-pop-btn-container">
            <div className="station-pop-right-btn">
              <CustomButton type="custom" label={"Cancel"} className="custom-btn-cancel" onClick={() => { setMeasurementControllerPop((prev) => ({ ...prev, open: false })) }} />
              <CustomButton type="custom" label={"Save"} onClick={() => {handleSubmit()}} />
            </div>
          </div>
        </div>
      </Dialog>
    );
  }

  const selectData = [
    { key: 'S', label: 'Single Qubits Mode' }
    , { key: 'M', label: 'Multi Qubits Mode' }
  ]


  function QASMDialog(props) {
    const { onClose, open } = props;

    const handleClose = () => {
      onClose();
    };

    return (
      <Dialog onClose={handleClose} open={open} className="qasm-pop-container">
        <DialogTitle className="qasm-pop-title-2">QASM Code</DialogTitle>
        <div className="qasm-pop-contents-2">
        <YamlEditor text={`OPENQASM 2.0;
  include "qelib1.inc";
  
  qreg q[5];
  creg m0[1];  
  
  cx q[0],q[2];
  
  h q[0];
  z q[1];
  
  z q[2];
  
  rz(pi*1.0) q[1];
  rx(pi*0.25) q[3];
  ry(pi*3.1415926536) q[4];
  
  rz(pi*3.1415926536) q[3];
  ry(pi*1.0) q[4];
  
  ch q[2],q[1];
  cx q[2],q[3];
  
  measure q[2] -> m477[0];`} onChange={(e) => { setData((prev) => ({ ...prev, configData: e?.text })) }} ref={configRef} theme={oneDark} />
        </div>
      </Dialog>
    );
  }


  const handleControllerChange = (type) => {

    if(type=='instrument') {
      if(data?.instrumentControllerInfo.length){
        history.push("/instrument-controller/f28ad1d9-6e46-44aa-a6fc-b51ebe5d06b3")
      }else {
        setInstrumentControllerPop((prev)=>({...prev, open:true}))
      }
    } else if (type=='measurement') {
      // setMeasurementControllerPop((prev)=>({...prev, open:true}))
      if(data?.measurementControllerInfo.length){
        history.push("/measurement-controller/d0befde5-6b00-4160-ab02-8b310375a1bd")
      }else {
        setMeasurementControllerPop((prev)=>({...prev, open:true}))
      }
    }

  }



  return <>
    <div className="station-info-page-container">
      <div className={`station-info-page-contents-container`}>
        <div>
          {/* Information 영역 */}
          <FieldGroup classNames={'station-info-page-station-container'}>
            <div className={`station-info-page-title-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
              Station Info
              <div className="station-info-page-title-container-update-btn" onClick={() => { setStationPop((prev) => ({ ...prev, open: true })) }}>
                <CustomIcon type={"faEdit"} />
              </div>

              <StationDialog
                open={stationPop?.open}
                onClose={() => { setStationPop((prev) => ({ ...prev, open: !stationPop?.open })) }}
              />
            </div>

            {/* Information Skeleton */}
            {loading && renderInfoSkeleton()}

            {/* Information Data Setting */}
            {!loading && <>
              {infoRenderManager()}
            </>}
          </FieldGroup>
        </div>

        
        <div>
          {/* Instrument Controller Info 영역 */}
          <FieldGroup>
            <div className={`station-info-page-title-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
              Instrument Controller Info
              <div className="station-info-page-title-container-update-btn" onClick={()=>{handleControllerChange('instrument')}}>
                <CustomIcon type={"faEdit"} />
              </div>
              <InstrumentControllerDialog
                open={instrumentControllerPop?.open}
                onClose={() => { setInstrumentControllerPop((prev) => ({ ...prev, open: !instrumentControllerPop?.open })) }}
              />
            </div>

            {/* Permissions Skeleton */}
            {loading && renderPermissionSkeleton()}

            {/* Permissions Data Setting */}
            {!loading && <>
              {instrumentControllerRenderManager()}
            </>}
          </FieldGroup>

          {/* Instrument Controller Info 영역 */}
          <FieldGroup>
            <div className={`station-info-page-title-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
              Measurement Controller Info
              <div className="station-info-page-title-container-update-btn" onClick={()=>{handleControllerChange('measurement')}}>
                <CustomIcon type={"faEdit"} />
              </div>
              <MeasurementControllerDialog
                open={measurementControllerPop?.open}
                onClose={() => { setMeasurementControllerPop((prev) => ({ ...prev, open: !measurementControllerPop?.open })) }}
              />
            </div>

            {/* Permissions Skeleton */}
            {loading && renderPermissionSkeleton()}

            {/* Permissions Data Setting */}
            {!loading && <>
              {measurementControllerRenderManager()}
            </>}
          </FieldGroup>

          {/* Circuit 영역 */}
          <FieldGroup>
            <div className={`station-info-page-title-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
              Circuit Info
            </div>

            {/* Ownership Skeleton */}
            {loading && renderOwnershipSkeleton()}

            {/* Ownership Data Setting */}
            {!loading && data?.instrumentControllerInfo.length>0 && <>
              <div className={`station-info-page-right-grid-onwership-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
                <div>Name</div>
                <div className=''>ces2024-QSVM</div>
                <div className={`station-info-page-right-grid-onwership-icon`}></div>
              </div>
              <div className={`station-info-page-right-grid-onwership-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
                <div>Description</div>
                <div className=''>Circuit Model for CES 2024</div>
                <div className={`station-info-page-right-grid-onwership-icon`}></div>
              </div>
              <div className={`station-info-page-right-grid-onwership-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
                <div>Qubit Count</div>
                <div className=''>2</div>
                <div className={`station-info-page-right-grid-onwership-icon`}></div>
              </div>
              <div className={`station-info-page-right-grid-onwership-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
                <div>QASM Code</div>
                <div className='station-info-page-right-grid-onwership-link' onClick={() => { setQasmPop((prev) => ({ ...prev, open: true })) }}>detail</div>
                <div className={`station-info-page-right-grid-onwership-icon`}></div>
              </div>
              <QASMDialog
                open={qasmPop?.open}
                onClose={() => { setQasmPop((prev) => ({ ...prev, open: !qasmPop?.open })) }}
              />
            </>}
          </FieldGroup>
        </div>
      </div>

      {/* Channel Conf 영역 */}
      <FieldGroup label={"Channel Conf"} foldable={true}>
        {/* Attributes Skeleton */}
        {loading && renderAttributesSkeleton()}

        {/* Attributes Data Setting */}
        {!loading && data?.instrumentControllerInfo.length>0 && <>
          <div className={`station-info-page-chnl-text-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
            <div className={`attr-text-item-header`}>Port No</div>
            <div className={`attr-text-item-header`}>Port Name</div>
            <div className={`attr-text-item-header`}>Channel Name</div>
            <div className={`attr-text-item-header`}>Connected</div>
          </div>
          <div className={`station-info-page-chnl-text-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
            <div className={`attr-text-item-no`}>1</div>
            <div className={`attr-text-item`}>o1</div>
            <div className={`attr-text-item`}>ch1out</div>
            <div className={`attr-text-item`}>(2) ces2024-QSVM.q[0]-readout, ces2024-QSVM.q[1]-readout</div>
          </div>
          <div className={`station-info-page-chnl-text-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
            <div className={`attr-text-item-no`}>2</div>
            <div className={`attr-text-item`}>i1</div>
            <div className={`attr-text-item`}>ch1in</div>
            <div className={`attr-text-item`}>(2) ces2024-QSVM.q[0]-feedback, ces2024-QSVM.q[1]-feedback</div>
          </div>
          <div className={`station-info-page-chnl-text-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
            <div className={`attr-text-item-no`}>3</div>
            <div className={`attr-text-item`}>o2</div>
            <div className={`attr-text-item`}>ch2</div>
            <div className={`attr-text-item`}>(1) ces2024-QSVM.q[0]-drive</div>
          </div>
          <div className={`station-info-page-chnl-text-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
            <div className={`attr-text-item-no`}>4</div>
            <div className={`attr-text-item`}>o3</div>
            <div className={`attr-text-item`}>ch3</div>
            <div className={`attr-text-item`}>(1) ces2024-QSVM.q[1]-drive</div>
          </div>
          <div className={`station-info-page-chnl-text-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
            <div className={`attr-text-item-no`}>5</div>
            <div className={`attr-text-item`}>o4</div>
            <div className={`attr-text-item`}>chf1</div>
            <div className={`attr-text-item`}>(1) ces2024-QSVM.q[0]-flux</div>
          </div>
          <div className={`station-info-page-chnl-text-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
            <div className={`attr-text-item-no`}>6</div>
            <div className={`attr-text-item`}>o5</div>
            <div className={`attr-text-item`}>chf2</div>
            <div className={`attr-text-item`}>(1) ces2024-QSVM.q[1]-flux</div>
          </div>
        </>}
      </FieldGroup>

      {/* Attributes 영역 */}
      {/* <FieldGroup label={"Attrubutes"} foldable={true}> */}
        {/* Attributes Skeleton */}
        {/* {loading && renderAttributesSkeleton()} */}

        {/* Attributes Data Setting */}
        {/* {!loading && <>
          <div className={`station-info-page-attr-text-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
            <div className={`attr-text-item`}>DESCRIPTION</div>
            <div className={`attr-text-item`}>Temp</div>
            <div className={`station-info-page-attr-btn-container`}>
            </div>
          </div>
          <div className={`station-info-page-attr-text-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
            <div className={`attr-text-item`}>MEMORY_UNIT_COST</div>
            <div className={`attr-text-item`}>MB</div>
            <div className={`station-info-page-attr-btn-container`}>
            </div>
          </div>
          <div className={`station-info-page-attr-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
            <TextField id={"attr_key"} label={"Attribute Name"} handleChange={() => { }} />
            <TextField id={"attr_value"} label={"Attribute Value"} handleChange={() => { }} />
            <div className={`station-info-page-attr-btn-container`}>
              <CustomButton type="iconPlus" onClick={() => { }} />
            </div>
          </div>
        </>} */}
      {/* </FieldGroup> */}
    </div>
  </>
}

export default Information;
