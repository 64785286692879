import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import "./SelectTable.css"
import { Link, Router } from 'react-router-dom';
import { useHistory } from 'react-router'

export default function EnhancedTable({ cols, list, type, callback, title, height, maxHeight }) {
  const themeColor = process.env?.REACT_APP_THEME;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const history = useHistory()

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(
    order,
    orderBy,
  ) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
  // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
  // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
  // with exampleArray.slice().sort(exampleComparator)
  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  function handleAllCheckButton(selectedRowCount) {
    if(selectedRowCount===list?.length) {
      if(callback)(callback([]))
    } else {
      if(callback)(callback(list))
    }
  }

  function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
      props;
    const createSortHandler =
      (property) => (event) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow size="small">
          <TableCell padding="checkbox" className={`${themeColor === 'dark' ? 'custom-mui-table-head-cell-dark' : 'custom-mui-table-head-cell'}`} size="small">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              className={`${selected?.length > 0 ? "custom-mui-select-table-head-check-active" : ""}`}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
              style={{pointerEvents:'all'}}
              onClick={()=>{handleAllCheckButton(selected?.length)}}
            />
          </TableCell>
          <TableCell style={{ width: 50 }} className="custom-mui-table-head-cell-dark" size="small">
            No
          </TableCell>
          {cols?.map((col) => {
            if (col.hidden) return
            return (
              <TableCell
                key={col.id}
                align={col.align}
                padding={'none'}
                sortDirection={orderBy === col.id ? order : false}
                className="custom-mui-table-head-cell-dark"
                size="small"
                style={{ minWidth: col.minWidth }}
              >
                <TableSortLabel
                  active={orderBy === col.id}
                  direction={orderBy === col.id ? order : 'asc'}
                  onClick={createSortHandler(col.id)}
                >
                  {col.label}
                  {orderBy === col.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )
          })}
        </TableRow>
      </TableHead>
    );
  }

  const handleRequestSort = (
    event,
    property,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = list?.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);

    if (callback) {
      newSelected?.sort()
      let selectedListData = []

      newSelected?.forEach(item => {
        selectedListData.push(list[item - 1])
      })
      callback(selectedListData)
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list?.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(list, getComparator(order, orderBy))?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, list],
  )

  // Link Type Table
  if (type === 'link') {
	  console.log("ooo")
    return (
      <>
        {title && <div style={{ fontFamily: '"Helvetica Neue", Roboto, Arial, sans-serif', fontSize: '14pt', fontWeight: 'bold', marginLeft: '1rem', marginBottom: '1rem' }} className={themeColor === 'dark' ? "dark-theme-tc" : ""}>{title}</div>}
        <Paper className={`${themeColor === 'dark' ? 'custom-mui-select-table-paper-dark' : 'custom-mui-select-table-paper'}`} sx={{ width: '100%', mb: 2 }}>
          <TableContainer className={`${themeColor === 'dark' ? 'custom-mui-select-table-container-dark' : 'custom-mui-select-table-container'}`} sx={{ height: height ? height : 200, maxHeight: maxHeight?maxHeight:300, borderBottom:'1px solid rgba(255,255,255,0.5)' }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
              stickyHeader
            >
              <EnhancedTableHead
                numSelected={selected?.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={list?.length}
              />
              <TableBody>
                {visibleRows?.map((row, index) => {
                  const isItemSelected = isSelected(row.id+index);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id+index}
                      selected={isItemSelected}
                      className={`${themeColor === 'dark' ? 'custom-mui-select-table-row-dark' : 'custom-mui-select-table-row'}`}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding="checkbox" className={`${themeColor === 'dark' ? 'custom-mui-table-body-cell-dark' : 'custom-mui-table-body-cell'}`} size="small" onClick={(event) => handleClick(event, row.id+index)}>
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell className={`${themeColor === 'dark' ? 'custom-mui-table-body-cell-dark' : 'custom-mui-table-body-cell'}`} size="small" onClick={() => { history.push(row?.link) }}>
                        <a>{(page * rowsPerPage) + index + 1}</a>
                      </TableCell>
                      {Object.keys(row)?.map((columnName, index) => {
                        if (cols.find(item => item.id === columnName)?.hidden) return
                        if (columnName === 'link') return

                        return (
                          <TableCell component="th" scope="row" padding="none" key={index} className={`${themeColor === 'dark' ? 'custom-mui-table-body-cell-dark' : 'custom-mui-table-body-cell'}`} size="small" onClick={() => { history.push(row?.link) }}>
                            <a>{row[columnName]}</a>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  );
                })}
                {/* {list?.length ===0 && (
                  <TableRow
                    className={`${themeColor === 'dark' ? 'custom-mui-select-table-row-dark' : 'custom-mui-select-table-row'}`}
                    style={{
                      height: 40 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={list?.length?list?.length:0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className="custom-mui-table-pagination"
          />
        </Paper>
      </>
    );



    // Normal Type Table
  } else {
    return (
      <>
        {title && <div style={{ fontFamily: '"Helvetica Neue", Roboto, Arial, sans-serif', fontSize: '14pt', fontWeight: 'bold', marginLeft: '1rem', marginBottom: '1rem' }} className={themeColor === 'dark' ? "dark-theme-tc" : ""}>{title}</div>}
        <Paper className={`${themeColor === 'dark' ? 'custom-mui-select-table-paper-dark' : 'custom-mui-select-table-paper'}`} sx={{ width: '100%', mb: 2 }}>
          <TableContainer className={`${themeColor === 'dark' ? 'custom-mui-select-table-container-dark' : 'custom-mui-select-table-container'}`} sx={{ height: height ? height : 200, maxHeight: maxHeight?maxHeight:300, borderBottom:'1px solid rgba(255,255,255,0.5)' }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
              className={`custom-mui-select-table`}
              stickyHeader
            >
              <EnhancedTableHead
                numSelected={selected?.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={list?.length}
              />
              <TableBody>
                {visibleRows?.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id+index}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                      className={`${themeColor === 'dark' ? 'custom-mui-select-table-row-dark' : 'custom-mui-select-table-row'}`}
                    >
                      <TableCell padding="checkbox" className={`${themeColor === 'dark' ? "custom-mui-table-body-cell-dark" : "custom-mui-table-body-cell"}`} size="small">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell className={`${themeColor === 'dark' ? "custom-mui-table-body-cell-dark" : "custom-mui-table-body-cell"}`} size="small">
                        {(page * rowsPerPage) + index + 1}
                      </TableCell>
                      {Object.keys(row)?.map((columnName, index) => {
                        if (cols.find(item => item.id === columnName)?.hidden) return
                        if (columnName === 'link') return

                        return (
                          <TableCell component="th" scope="row" padding="none" key={index} className={`${themeColor === 'dark' ? "custom-mui-table-body-cell-dark" : "custom-mui-table-body-cell"}`} size="small">
                            {row[columnName]}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  );
                })}
                {list?.length === 0 && (
                  <TableRow
                    className={`${themeColor === 'dark' ? 'custom-mui-select-table-row-dark' : 'custom-mui-select-table-row'}`}
                    style={{
                      height: 40 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={list?.length?list?.length:0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className="custom-mui-table-pagination"
          />
        </Paper>
      </>
    );
  }
}
