export const baseUrl = document.location.protocol + "//api.sqkcloud.com/";
export const qecApiUrl = document.location.protocol+"//qec-api.sqkcloud.com/";
export const currentUrl = document.location.protocol + "//sq.sqkcloud.com/";
// export const currentUrl = document.location.protocol + "//192.168.10.44:3000/";
export const serverUrl = document.location.protocol + "//localhost:4000/";
export const circuitUrl =
  document.location.protocol + "//circuit.sqkcloud.com/";
export const jupyterUrl = "http://localhost:8888/";
export const analysisUrl =
  document.location.protocol + "//analysis.sqkcloud.com/";
export const sim1JupyterUrl =
  document.location.protocol + "//sqk-sim-1.sqkcloud.com/";
export const sim2JupyterUrl =
  document.location.protocol + "//sqk-sim-2.sqkcloud.com/";
export const itkUrl =
  document.location.protocol +
  "//itk.sqkcloud.com/?fileToLoad=/data/Concord.vtk";
