import React, { useEffect, useState } from "react";
import SelectTable from "../../components/Table/SelectTable";
import CustomButton from "../../components/Button/CustomButton.jsx";
import { useHistory } from "react-router";
import { tSuccess } from "../../common/CommonToast.jsx";
import AlertDialog from "../../components/Dialog/index.jsx";
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";



function TableArea({ checked }) {
  const [data, setData] = useState({});
  const history = useHistory();
  const {id} = useParams()
  const [list, setList] = useState([]);
  useEffect(()=>{
    if(id=="ibm"){
      setList([])
      setList([{
        id: 1,
        name: "ibm_station_1",
        description: "ibm_station_1",
        owner: "SQK Cloud",
        resonatorType: "M",
        status: "RUNNING",
        link:"/platform/0e4a9ea4-9c35-4de1-ad37-f1c79edd247d",
        action: (
          <>
            {computerIcon}
            {arrowIcon}
          </>
        ),
      }])
    } else if(id=="ionq") {
      setList([])
      setList([{
        id: 1,
        name: "ionq_station_1",
        description: "ionq_station_1",
        owner: "SQK Cloud",
        resonatorType: "M",
        status: "RUNNING",
        link:"/platform/16dbded0-5611-4fa0-886b-23e4728118f5",
        action: (
          <>
            {computerIcon}
            {arrowIcon}
          </>
        ),
      }])
    } else if(id=="google") {
      setList([])
      setList([{
        id: 1,
        name: "google_station_1",
        description: "google_station_1",
        owner: "SQK Cloud",
        resonatorType: "M",
        status: "RUNNING",
        link:"/platform/98d17914-f587-48d2-b011-8c2985cb4cf5",
        action: (
          <>
            {computerIcon}
            {arrowIcon}
          </>
        ),
      }])
    } else if(id=="azure") {
      setList([])
      setList([{
        id: 1,
        name: "azure_station_1",
        description: "azure_station_1",
        owner: "SQK Cloud",
        resonatorType: "M",
        status: "RUNNING",
        link:"/platform/19cfa27c-3ce3-42df-a055-7ec31f11d512",
        action: (
          <>
            {computerIcon}
            {arrowIcon}
          </>
        ),
      }])
    } else if(id=="aws") {
      setList([])
      setList([{
        id: 1,
        name: "aws_station_1",
        description: "aws_station_1",
        owner: "SQK Cloud",
        resonatorType: "M",
        status: "RUNNING",
        link:"/platform/60abaa73-12f1-4297-af2d-17cc75f83fb9",
        action: (
          <>
            {computerIcon}
            {arrowIcon}
          </>
        ),
      }])
    } else if(id=="d-wave") {
      setList([])
      setList([{
        id: 1,
        name: "d-wave_station_1",
        description: "d-wave_station_1",
        owner: "SQK Cloud",
        resonatorType: "M",
        status: "RUNNING",
        link:"/platform/a272c7ef-d57c-4e7f-83d6-318656628ca9",
        action: (
          <>
            {computerIcon}
            {arrowIcon}
          </>
        ),
      }])
    } else {
      setList([])
    }
  },[id])


  const columns = [
    { id: "id", label: "ID", minWidth: 100, hidden: true },
    { id: "name", label: "Model Name", minWidth: 70 },
    { id: "description", label: "Description", minWidth: 100 },
    {
      id: "owner",
      label: "Owner",
      minWidth: 170,
      align: "left",
    },
    { id: "resonatorType", label: "Resonator Type", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
  ];
  const computerIcon = (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="desktop"
      class="svg-inline--fa fa-desktop "
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
      style={{ fontSize: "20px" }} // 수정된 부분
    >
      <path
        fill="currentColor"
        d="M64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64H240l-10.7 32H160c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H346.7L336 416H512c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64zM512 64V288H64V64H512z"
      ></path>
    </svg>
  );
  
  const arrowIcon = (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="circle-up"
      class="svg-inline--fa fa-circle-up "
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      style={{ marginLeft: "20px", transform: "rotate(45deg)", fontSize: "20px" }} // 수정된 부분
    >
      <path
        fill="currentColor"
        d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM135.1 217.4l107.1-99.9c3.8-3.5 8.7-5.5 13.8-5.5s10.1 2 13.8 5.5l107.1 99.9c4.5 4.2 7.1 10.1 7.1 16.3c0 12.3-10 22.3-22.3 22.3H304v96c0 17.7-14.3 32-32 32H240c-17.7 0-32-14.3-32-32V256H150.3C138 256 128 246 128 233.7c0-6.2 2.6-12.1 7.1-16.3z"
      ></path>
    </svg>
  );
  
  
  const setRowEvt = (rowData) => {
    console.log(rowData);
    setData((prev)=>({...prev, selectedRowData: rowData}))
  };

  // Delete Event
  const deleteData = () => {
    const deleteTargetList = data?.selectedRowData
    console.log("deleteTargetList : ",deleteTargetList)
    tSuccess("Deleted!")
  }

  const handlePopupResult = ({id,result}) => {
    if(id=="delete"&&result){
      tSuccess("Deleted!!")
    }
  }

  const renderDeleteButton = ()=>{
    const runPopContents = {
      title:"Confirm"
      ,contents:"Would you like to delete it?"
      ,agreeBtnLabel:"OK"
      ,disagreeBtnLabel: "Cancel"
    }

    return (
      <AlertDialog type="delete" id="delete" disabled={data?.selectedRowData?.length?false:true} callback={handlePopupResult} contents={runPopContents} />
    )
  }

  return (
    <>
      <div className="sunstone-list">    
        <SelectTable
          cols={columns}
          list={list}
          callback={setRowEvt}
          type={"link"}
        />
      </div>

    </>
  );
}

export default TableArea;
