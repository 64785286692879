import "./Information.css";
import CustomIcon from "../../../../../components/Icon";
import FieldGroup from "../../../../../components/FieldGroup";
import TextField from "../../../../../components/TextField";
import CustomButton from "../../../../../components/Button/CustomButton"
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useRef, useState } from "react";
import { 
  renderInfoSkeleton
  ,renderPermissionSkeleton
  ,renderOwnershipSkeleton
  ,renderAttributesSkeleton 
} from "./InformationRenderer";
import { useParams } from "react-router";
import axiosService from "../../../../../api/axiosService";
import { baseUrl } from "../../../../../redux/contants/urlApi";
import YamlEditor from "@focus-reactive/react-yaml";
import { oneDark } from "@codemirror/theme-one-dark";
import { getChannelConf } from "../../../../../common/CommonUtil";

const Information = ({ }) => {
  const themeColor = process.env?.REACT_APP_THEME;
  const [data, setData] = useState({instrumentInfo:null});
  const [loading, setLoading] = useState(true)
  const { id } = useParams();
  const configRef = useRef();

  useEffect(() => {
    console.log("data : ", data)
  }, [data])

  useEffect(() => {
    // 임시셋팅
    handleFetch()
    setTimeout(() => {
      setLoading(false)
    }, 450)


    setData((prev)=>({...prev, configData: `instruments:
    # Each instrument is specified by its name.
    # This name is what is looked up by the 'load_instrument'
    # method of 'Station'.
    # Simulated instruments can also be specified here, just put
    # the path to the simulation .yaml file as the value of the
    # "init"->"visalib" field (see below for an example of the
    # "init" section as well as an example of specifying
    # a simulated instrument).
    qdac:
        # Full import path to the python class of the instrument
        # driver
        type: qcodes.instrument_drivers.QDev.QDevQDac
        # Visa address of the instrument.
        # Note that this field can also be specified in the
        # "init" section (see below) but the address specified
        # here will overrule the address from the "init" section.
        # Essentially, specifying address here allows avoiding
        # the "init" section completely when address is the only
        # necessary argument that the instrument driver needs.
        # For obvious reasons, this field is required for VISA
        # instruments.
        address: ASRL4::INSTR
        # If an instrument with this name is already instantiated,
        # and this field is true, then the existing instrument
        # instance will be closed before instantiating this new one.
        # If this field is false, or left out, closing will not
        # happen.
        enable_forced_reconnect: true
        #
        # The "init" section specifies constant arguments that are
        # to be passed to the __init__ function of the instrument.
        # Note that it is the instrument's driver class that defines
        # the allowed arguments, for example, here "update_currents"
        # is an argument that is specific to "QDac" driver.
        init:
        terminator: \n
        update_currents: false
        #
        # Setting up properties of parameters that already exist on
        # the instrument.
        parameters:
        # Each parameter is specified by its name from the
        # instrument driver class.
        # Note that "dot: notation can be used to specify
        # parameters in (sub)channels and submodules.
        ch01.v:
            # If an alias is specified, the parameter becomes  
            # accessible under another name, so that you can write
            # 'qdac.cutter_gate(0.2)' instead of 'qdac.ch01.v(0.2)'.
            # Note that the parameter instance does not get copied,
            # so that '(qdac.ch01.v is qdac.cutter_gate) == True'.
            alias: cutter_gate
            # Set new label.
            label: Cutter Gate Voltage
            # Set new unit.
            unit: mV
            # Set new scale.
            scale: 0.001
            # Set new post_delay.
            post_delay: 0
            # Set new inter_delay.
            inter_delay: 0.01
            # Set new step.
            step: 1e-4
            # If this field is given, and contains an array of two
            # numbers like here, then the parameter
            # gets a new 'Numbers' validator with these values as
            # lower and upper limits, respectively (in this case, it
            # is 'Numbers(-0.1, 0.1)').
            limits: [-0.1, 0.1]
            # Set the parameter to this given initial value upon
            # instrument initialization.
            # Note that if the current value on the physical
            # instrument is different, the parameter will be ramped
            # with the delays and step specified in this file.
            initial_value: 0.01
            # In case this values equals to true, upon loading this
            # instrument from this configuration this parameter will
            # be appended to the list of parameters that are
            # displayed in QCoDeS 'Monitor'.
            monitor: true
        # As in all YAML files a one-line notation can also be
        # used, here is an example.
        ch02.v: {scale: 0.01, limits: [0.0, 1.5e+3] , label: my label}
        ch04.v: {alias: Q1lplg1, monitor: true}
        #
        # This section allows to add new parameters to the
        # instrument instance which are based on existing parameters
        # of the instrument. This functionality is based on the use
        # of the 'DelegateParameter' class.
        add_parameters:
        # For example, here we define a parameter that represents
        # magnetic field control. Setting and getting this
        # parameter will actually set/get a specific DAC channel.
        # So this new magnetic field parameter is playing a role
        # of a convenient proxy - it is much more convenient to
        # perform a measurement where "Bx" is changed in tesla as
        # opposed to where some channel of some DAC is changed in
        # volts and one has to clutter the measurement code with
        # the mess of conversion factors and more.
        # Every new parameter definition starts with a name of
        # the new parameter.
        Bx:
            # This field specifies the parameter which "getter" and
            # "setter" will be used when calling 'get'/'set' on this
            # new parameter.
            # Required field.
            source: ch02.v
            # Set the label. Otherwise, the one of the source parameter
            # will be used.
            label: Magnetic Field X-Component
            # Set the unit. Otherwise, the one of the source parameter
            # will be used.
            unit: T
            # Other fields have the same purpose and behavior as for
            # the entries in the 'add_parameter' section.
            scale: 65.243
            inter_delay: 0.001
            post_delay: 0.05
            step: 0.001
            limits: [0.0, 3.0]
            initial_value: 0.0
            # For the sake of example, we decided not to monitor this
            # parameter in QCoDeS 'Monitor'.
            #monitor: true
    #
    # More example instruments, just for the sake of example.
    # Note that configuring simulated instruments also works,
    # see the use of 'visalib' argument field below
    dmm1:
        type: qcodes.instrument_drivers.agilent.Agilent34401A
        enable_forced_reconnect: true
        address: GPIB::1::65535::INSTR
        init:
        visalib: 'Agilent_34400A.yaml@sim'
        parameters:
        volt: {monitor: true}
    mock_dac:
        type: qcodes.instrument_drivers.mock_instruments.DummyInstrument
        enable_forced_reconnect: true
        init:
        # To pass an list of items use {}.
        gates: {"ch1", "ch2"}
        add_parameters:
        Bx: {source: ch1, label: Bx, unit: T,
                scale: 28, limits: [-1, 1], monitor: true}
    mock_dac2:
        type: qcodes.instrument_drivers.mock_instruments.DummyInstrument
        enable_forced_reconnect: true`}))
  }, [])


  const handleFetch = async () => {
    try {
      let res = await axiosService.get(baseUrl+"v1/instruments/"+id+'/detail');
      if (res?.status == 200) {
        
        // 기본정보 조회
        let instrumentInfo = {
          id : id
          ,name: res?.data?.name
          ,description: res?.data?.description
          ,status: res?.data?.status
          ,address: res?.data?.address
          ,port: res?.data?.port
          ,createdAt: res?.data?.created_at
          ,owner: res?.data?.created_user?.username
        }
        setData((prev)=>({...prev, instrumentInfo: instrumentInfo}))


        let channelConf = await getChannelConf(id);
        // 선번장 조회
        if(channelConf?.status == 'S') {
          setData((prev)=>({...prev, channelConf: channelConf?.data}))
        }
      }
    } catch (e) {
      console.log(e)
    }
  }


  const renderInfoDiv = (key, value, icon) => {
    return (
      <div className={`info-page-left-grid-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
        <div>{key}</div>
        <div>{value}</div>
        <div className={`info-page-left-grid-icon`}>{icon}</div>
      </div>
    )
  }

  return <>
    <div className="info-page-container">
      <div className={`info-page-contents-container`}>
        <div>
          {/* Information 영역 */}
          <FieldGroup>
            <div className={`info-page-title-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
              Information
            </div>

            {/* Information Skeleton */}
            {loading && renderInfoSkeleton()}

            {/* Information Data Setting */}
            {!loading && <>
              {renderInfoDiv("ID", data?.instrumentInfo?.id, null)}
              {renderInfoDiv("Name", data?.instrumentInfo?.name, <CustomIcon type={"faEdit"} />)}
              {renderInfoDiv("Description", data?.instrumentInfo?.description, <CustomIcon type={"faEdit"} />)}
              {renderInfoDiv("State", data?.instrumentInfo?.status, null)}
              {renderInfoDiv("Resonator Type", "M", null)}
              {renderInfoDiv("LCM State", "LCM_INIT", null)}
              {renderInfoDiv("Address", data?.instrumentInfo?.address, null)}
              {renderInfoDiv("Port", data?.instrumentInfo?.port, null)}
              {renderInfoDiv("Created at", data?.instrumentInfo?.createdAt, null)}
              {renderInfoDiv("Deploy ID", "-", null)}
              {renderInfoDiv("Reschedule", "no", null)}
              {renderInfoDiv("Virtual Router", "-", null)}
              <div style={{marginTop:'2rem'}}>
                <div className={`driver-info-page-right-image-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
                  <img src="/assets/quantums/quantum-5.jpg" ></img>
                </div>
              </div>
            </>}
          </FieldGroup>
        </div>

        {/* Permissions 영역 */}
        <div>
          <FieldGroup>
            <div className={`info-page-title-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
              Permissions
            </div>

            <div className={`info-page-right-grid-container header ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
              <div></div>
              <div className={`item`}>Use</div>
              <div className={`item`}>Manage</div>
              <div className={`item`}>Admin</div>
            </div>

            {/* Permissions Skeleton */}
            {loading && renderPermissionSkeleton()}

            {/* Permissions Data Setting */}
            {!loading && <>
              <div className={`info-page-right-grid-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
                <div>Owner</div>
                <Checkbox className={`item`} style={{ pointerEvents: true ? 'none' : 'all' }} />
                <Checkbox className={`item`} style={{ pointerEvents: true ? 'none' : 'all' }} checked={true} />
                <Checkbox className={`item`} style={{ pointerEvents: true ? 'none' : 'all' }} />
              </div>
              <div className={`info-page-right-grid-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
                <div>Group</div>
                <Checkbox className={`item`} style={{ pointerEvents: true ? 'none' : 'all' }} checked />
                <Checkbox className={`item`} style={{ pointerEvents: true ? 'none' : 'all' }} checked />
                <Checkbox className={`item`} style={{ pointerEvents: true ? 'none' : 'all' }} />
              </div>
              <div className={`info-page-right-grid-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
                <div>Other</div>
                <Checkbox className={`item`} style={{ pointerEvents: true ? 'none' : 'all' }} />
                <Checkbox className={`item`} style={{ pointerEvents: true ? 'none' : 'all' }} />
                <Checkbox className={`item`} style={{ pointerEvents: true ? 'none' : 'all' }} checked />
              </div>
            </>}
          </FieldGroup>
          
          {/* Ownership 영역 */}
          <FieldGroup>
            <div className={`info-page-title-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
              Ownership
            </div>

            {/* Ownership Skeleton */}
            {loading && renderOwnershipSkeleton()}

            {/* Ownership Data Setting */}
            {!loading && <>
              <div className={`info-page-right-grid-onwership-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
                <div>Owner</div>
                <div className='info-page-right-grid-onwership-link'>{data?.instrumentInfo?.owner}</div>
                <div className={`info-page-right-grid-onwership-icon`}><CustomIcon type={"faEdit"} /></div>
              </div>
              <div className={`info-page-right-grid-onwership-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
                <div>Group</div>
                <div className='info-page-right-grid-onwership-link'>oneadmin</div>
                <div className={`info-page-right-grid-onwership-icon`}><CustomIcon type={"faEdit"} /></div>
              </div>
            </>}
          </FieldGroup>
        </div>
      </div>


      {/* <FieldGroup foldable={true} label={"Channel Conf"}>
      {loading && renderOwnershipSkeleton()}
      {!loading&&<>
          <div className={`station-info-page-chnl-text-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
            <div className={`attr-text-item-header`}>Port No</div>
            <div className={`attr-text-item-header`}>Port Name</div>
            <div className={`attr-text-item-header`}>Channel Name</div>
            <div className={`attr-text-item-header`}>Connected</div>
          </div>
          {data?.channelConf?.map((item)=>{
            return (
              <div className={`station-info-page-chnl-text-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
                <div className={`attr-text-item-no`}>{item?.port_no}</div>
                <div className={`attr-text-item`}>{item?.port_name}</div>
                <div className={`attr-text-item`}>{item?.channel_name}</div>
                <div className={`attr-text-item`}>{item?.connected_qubits}</div>
              </div>
            )
          })}
        </>}
      
      </FieldGroup> */}

      
      {/* <FieldGroup label={"Config"} foldable={true} defaultFold={false}>
        <YamlEditor text={data?.configData} onChange={(e)=>{setData((prev)=>({...prev, configData:e?.text}))}} ref={configRef} theme={oneDark} />
      </FieldGroup> */}
    </div>
  </>
}

export default Information;
