import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShuffle,
  faFolderOpen,
  faDownload,
  faArchive,
  faShoppingCart,
  faCloudDownload,
  faServer,
  faHdd,
  faGlobe,
  faUser,
  faUsers,
  faThList,
  faKey,
  faGauge,
  faBolt,
  faGopuram,
  faCircleNodes,
  faCropSimple,
  faDatabase,
  faNetworkWired,
  faComputer,
  faTable,
  faGears,
  faChargingStation,
  faPersonDotsFromLine,
  faHardDrive,
  faChartSimple,
  faBook,
  faUserCheck,
  faHeadSideVirus,
  faCompass,
  faSkiingNordic,
  faMars,
  faRubleSign,
  faHollyBerry
} from "@fortawesome/free-solid-svg-icons";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { jupyterUrl, analysisUrl, itkUrl } from "../redux/contants/urlApi";

import { SiIbm } from "react-icons/si";
import { FaComputer } from "react-icons/fa6";
import { FaAws } from "react-icons/fa";
import { SiMicrosoftazure } from "react-icons/si";
import { FaGoogle } from "react-icons/fa";

export const sidebarLink = [
  {
    icon: <FontAwesomeIcon icon={faGauge} />,
    title: "Dashboard",
    link: "/",
    sub: [],
  },
  {
    icon: <FontAwesomeIcon icon={faBolt} />,
    title: "Quantum Controller",
    sub: [
      {
        icon: <FontAwesomeIcon icon={faChargingStation} />,
        title: "Station",
        link: "/platform/list",
      },
      {
        icon: <FontAwesomeIcon icon={faShuffle} />,
        title: "Instrument Controller",
        link: "/instrument-controller/list",
      },
      {
        icon: <FontAwesomeIcon icon={faPersonDotsFromLine} />,
        title: "Measurement Controller",
        link: "/measurement-controller/list",
      },
    ],
  },
  {
    icon: <FontAwesomeIcon icon={faGopuram} />,
    title: "Driver",
    sub: [
      {
        icon: <FontAwesomeIcon icon={faHardDrive} />,
        title: "Instrument Controller Driver",
        link: "/instrument-driver/list",
      },
      {
        icon: <FontAwesomeIcon icon={faHardDrive} />,
        title: "Measurement Controller Driver",
        link: "/measurement-driver/list",
      },
    ],
  },
  {
    icon: <FontAwesomeIcon icon={faCircleNodes} />,
    title: "Circuit",
    sub: [
      {
        icon: <FontAwesomeIcon icon={faChartSimple} />,
        title: "Circuit Editor",
        link: "/circuit",
      },
      {
        icon: <FontAwesomeIcon icon={faChartSimple} />,
        title: "Circuit Optimizer",
      },
    ],
  },
  {
    icon: <FontAwesomeIcon icon={faCropSimple} />,
    title: "Quantum HPC",
    sub: [
      // {
      //   icon: <FontAwesomeIcon icon={faBook} />,
      //   title: "Notebook (IDE)",
      //   link: jupyterUrl,
      //   isPop: true,
      // },
      {
        icon: <FontAwesomeIcon icon={faCropSimple} />,
        title: "Compilers",
        link: "/simulation/simulator",
      },
      {
        icon: <FontAwesomeIcon icon={faSkiingNordic} />,
        title: "Workflow",
        link: "http://104.42.6.128:8080/home",
        isPop: true,
      },
      {
        icon: <FontAwesomeIcon icon={faMars} />,
        title: "Job",
        link: "",
      },
      {
        icon: <FontAwesomeIcon icon={faRubleSign} />,
        title: "Matlab",
        link: "https://matlab.mathworks.com/",
        isPop: true,
      },
      {
        icon: <FontAwesomeIcon icon={faUserCheck} />,
        title: "Agorithms",
      },
      {
        icon: <FontAwesomeIcon icon={faChartSimple} />,
        title: "QCFD",
        link: itkUrl,
        isPop: true,
      },
      {
        icon: <FontAwesomeIcon icon={faHollyBerry} />,
        title: "QEC",
        link: "/simulation/panqec",
      },
    ],
  },
  {
    icon: <FontAwesomeIcon icon={faHeadSideVirus} />,
    title: "Backend",
    sub: [
      {
        icon: <SiIbm size={20} />,
        title: "IBM",
        link: "/backend/ibm",
      },
      {
        icon: <FaComputer size={20} />,
        title: "IonQ",
        link: "/backend/ionq",
      },
      {
        icon: <FaGoogle size={20} />,
        title: "Google",
        link: "/backend/google",
      },
      {
        icon: <SiMicrosoftazure size={20} />,
        title: "Azure",
        link: "/backend/azure",
      },
      {
        icon: <FaAws size={20} />,
        title: "AWS",
        link: "/backend/aws",
      },
      {
        icon: <FontAwesomeIcon icon={faCompass} />,
        title: "D-WAVE",
        link: "/backend/d-wave",
      },
    ],
  },
  {
    icon: <FontAwesomeIcon icon={faDatabase} />,
    title: "Storage",
    sub: [
      {
        icon: <FontAwesomeIcon icon={faFolderOpen} />,
        title: "Datastores",
      },
      {
        icon: <FontAwesomeIcon icon={faDownload} />,
        title: "Images",
      },
      {
        icon: <FontAwesomeIcon icon={faArchive} />,
        title: "Files",
      },
      {
        icon: <FontAwesomeIcon icon={faShoppingCart} />,
        title: "MarketPlaces",
      },
      {
        icon: <FontAwesomeIcon icon={faCloudDownload} />,
        title: "Apps",
      },
    ],
  },
  // {
  //   icon: <FontAwesomeIcon icon={faNetworkWired} />,
  //   title: "Infrastructure",
  //   sub: [
  //     {
  //       icon: <FontAwesomeIcon icon={faServer} />,
  //       title: "Clusters",
  //     },
  //     {
  //       icon: <FontAwesomeIcon icon={faHdd} />,
  //       title: "Hosts",
  //     },
  //     {
  //       icon: <FontAwesomeIcon icon={faGlobe} />,
  //       title: "Zones",
  //     },
  //   ],
  // },
  // {
  //   icon: <FontAwesomeIcon icon={faComputer} />,
  //   title: "System",
  //   sub: [
  //     {
  //       icon: <FontAwesomeIcon icon={faUser} />,
  //       title: "Users",
  //       link: "/user/list",
  //     },
  //     {
  //       icon: <FontAwesomeIcon icon={faUsers} />,
  //       title: "Groups",
  //     },
  //     {
  //       icon: <FontAwesomeIcon icon={faThList} />,
  //       title: "VDCs",
  //     },

  //     {
  //       icon: <FontAwesomeIcon icon={faKey} />,
  //       title: "ACLs",
  //     },
  //   ],
  // },
  {
    icon: <FontAwesomeIcon icon={faTable} />,
    title: "Benchmark",
    link: "/benchmark",
    sub: [],
  },
  {
    icon: <FontAwesomeIcon icon={faTable} />,
    title: "Transpiler",
    link: "/transpiler",
    sub: [],
  },
  {
    icon: <FontAwesomeIcon icon={faTable} />,
    title: "Transpiler2",
    link: "/transpiler2",
    sub: [],
  },
  {
    icon: <FontAwesomeIcon icon={faTable} />,
    title: "Data Exploratory",
    link: analysisUrl,
    isPop: true,
    sub: [],
  },
  {
    icon: <FontAwesomeIcon icon={faGears} />,
    title: "Settings",
    link: "/setting",
    sub: [],
  },
  {
    icon: <EditNoteIcon sx={{ color: "skyblue" }} />,
    title: <div style={{ color: "skyblue" }}>Release Note</div>,
    link: "/release",
    sub: [],
  },
];

export const quantumSystems = [
  {
    id: 1,
    name: "Zurich Instruments",
    // image: "/assets/quantums/icon_quantum.jpg",
    image: "/assets/quantums/Zurich_Instruments_logo.png",
    quantumImage: "/assets/quantums/quantum-5.jpg",
    time: "24m ago",
    user: "admin",
    firmWare: "Latest (July 2023)",
    softWare: "LabOneQ 2.7.0",
    width: "130px",
  },
  {
    id: 2,
    name: "Quantum Controller",
    image: "/assets/quantums/2-QM-logo.svg",
    quantumImage: "/assets/quantums/quantum-3.jpg",
    time: "1h ago",
    user: "admin",
    firmWare: "QOP213",
    softWare: "qm-qua 1.1.1",
    width: "160px",
  },
  {
    id: 3,
    name: "QBlox",
    image: "/assets/quantums/3-QBlox-logo.svg",
    quantumImage: "/assets/quantums/quantum-2.jpg",
    time: "35m ago",
    user: "admin",
    firmWare: "0.4.0",
    softWare: "qblox-instruments 0.9.0",
    width: "150px",
  },
  {
    id: 4,
    name: "QICK",
    image: "/assets/quantums/4-qick-logo2.svg",
    quantumImage: "/assets/quantums/quantum-1.jpg",
    time: "2h ago",
    user: "admin",
    firmWare: "0.4.0",
    softWare: "qblox-instruments 0.9.0",
    width: "37px",
  },
  {
    id: 5,
    name: "Dummy Instrument",
    image: "/assets/quantums/4-qick-logo2.svg",
    quantumImage: "/assets/quantums/quantum-4.jpg",
    time: "1h ago",
    user: "admin",
    firmWare: "0.4.0",
    softWare: "qblox-instruments 0.9.0",
    width: "37px",
  },
];
