import YamlEditor from "@focus-reactive/react-yaml";
import { oneDark } from "@codemirror/theme-one-dark";
import ListTable from "../../../../../components/Table/ListTable.jsx";
import { useState, useRef, useEffect } from "react";

// styled components
import * as Styled from "./circuitSelect.styled.js";
import axiosService from "../../../../../api/axiosService.js";
import { baseUrl } from "../../../../../redux/contants/urlApi.js";
import { convertDateFormat } from "../../../../../common/CommonUtil.jsx";
import { useParams } from "react-router";
import { tError, tSuccess } from "../../../../../common/CommonToast.jsx";
import { ConfirmDialog } from "../../../../../components/Dialog/index.jsx";

const CircuitSelect = ({ circuitInfo, setCircuitInfo, setPageStep, updateFlag, setUpdateFlag }) => {
  const themeColor = process.env?.REACT_APP_THEME;
  // Yamleditor
  const editorRef = useRef(null);

  // circuit select - circuit info 활성화 시키기 위한 state
  const [circuitSelect, setCircuitSelect] = useState(false);
  const [data, setData] = useState({});
  const { id } = useParams();
  const [defaultValueIdx, setDefaultValueIdx] = useState(null);
  const [updatePop,setUpdatePop] = useState(false);

  // table
  const columns = [
    { id: "id", label: "ID", minWidth: 100, hidden: true },
    { id: "name", label: "Circuit Name", minWidth: 70 },
    { id: "description", label: "Description", minWidth: 200 },
    {
      id: "qubits",
      label: "Qubits",
      minWidth: 70,
    },
    {
      id: "date",
      label: "Created at",
      minWidth: 100,
    },
    {
      id: "runCard",
      label: "Run Card",
      hidden: true,
    },
  ];

  useEffect(()=>{
    handleFetch()
  },[])

  const handleFetch = async () => {
    try {
      let circuitListRes = await axiosService.get(baseUrl+"v1/circuits/")
      let tempList=[];

      if(circuitListRes?.status == 200) {
        tempList = circuitListRes?.data?.map((item)=>({
          id: item.id
          ,name: item.name
          ,description: item.description
          ,qubits: item.qubit_count
          ,date: convertDateFormat(item.created_at)
          ,runCard: item.circuit_content
        }))
        setData((prev)=>({...prev, circuitList: tempList}))
      }

      let instrumentsRes = await axiosService.get(baseUrl+"v1/instruments/"+id+'/detail')
      if(instrumentsRes?.status == 200){
        if(instrumentsRes?.data?.circuit_id) {
          setData((prev)=>({...prev, instrumentInfo: instrumentsRes?.data}))
          setCircuitSelect(true);
          setCircuitInfo(tempList?.find(item=>item.id == instrumentsRes?.data?.circuit_id));
          setDefaultValueIdx(tempList?.findIndex(item=>item.id == instrumentsRes?.data?.circuit_id))
        }
      }
    } catch (e) {
      console.log(e)
    }
  }


  const setRowEvt = (rowData) => {
    console.log("rowData:",rowData);
    // circuit info 활성화
    setCircuitSelect(true);

    if (editorRef.current && rowData !== undefined && rowData !== null) {
      setCircuitInfo(rowData);
    }
  };

  const unsetRowEvt = (rowData) => {
    // circuit info 비활성화
    setCircuitSelect(false);
  };


  // yaml editor에 circuit info 넣기
  useEffect(() => {
    if (
      circuitInfo !== null &&
      circuitInfo !== undefined &&
      editorRef.current
    ) {
      try {
        editorRef.current.replaceValue({ json: JSON.parse(circuitInfo.runCard) });
      } catch (e){
        editorRef.current.replaceValue({ json: circuitInfo.runCard });
      }
    }
  }, [circuitInfo]);

  // select 버튼
  const handleSelect = async () => {
    if(!data?.instrumentInfo?.circuit_id){
      handleUpdate();
    } else {
      if(data?.instrumentInfo?.circuit_id==circuitInfo?.id) {
        setPageStep(2);
      } else {
        setUpdatePop(true)
      }
    }
  };

  const renderConfirmPop = () => {
    let type = !data?.instrumentInfo?.circuit_id?"Select":"Update";
    let originCircuitName = null;
    let updateCircuitName = circuitInfo?.name;
    if(type=="Update"){
      originCircuitName = data?.circuitList?.find(item=>item?.id ==data?.instrumentInfo?.circuit_id)?.name;
    }

    const runPopContents = {
      title: "Confirm",
      contents: (
        type=="Select"?
        (<div>
          Select Circuit <span style={{fontWeight:'bold'}}>"{updateCircuitName}"</span>
          <div style={{bottom:'0.5rem'}}>
            Is the information correct?
          </div>
        </div>):
        (<div>
          Update Circuit <span style={{fontWeight:'bold'}}>"{originCircuitName}"</span> to <span style={{fontWeight:'bold'}}>"{updateCircuitName}"</span>
          <div style={{bottom:'0.5rem'}}>
            Is the information correct?
          </div>
        </div>)
      ),
      agreeBtnLabel: "OK",
      disagreeBtnLabel: "Cancel",
    };

    return (
      <ConfirmDialog
        id="asds"
        callback={handlePopupResult}
        contents={runPopContents} />
    )
    
  }

  const handlePopupResult = (rslt) => {
    setUpdatePop(false)
    if(rslt?.result){
      handleUpdate()
    }
    console.log()
  }

  const validateCheckToUpdate = async () => {
    try {
      
    } catch (e) {
      console.log(e)
    }
  }


  const handleUpdate = async () => {
    try {
      let baseRes = await axiosService.get(baseUrl+"v1/instruments/"+id+'/detail')
      
      if(baseRes?.status == 200){
        let baseData = baseRes?.data
        let updateParam = {
          name: baseData?.name
          ,description: baseData?.description
          ,address: baseData?.address
          ,port: baseData?.port
          ,driver_id_or_uuid: baseData?.instrument_driver?.uuid
          ,circuit_id: circuitInfo?.id
          ,status: baseData?.status
        }

        let updateRes = await axiosService.put(baseUrl+"v1/instruments/"+id, updateParam)
        if(updateRes?.status == 201){
          setPageStep(2);
        } else {
          tError("Update Failed")
        }
      } else {
         tError("Update Failed")
      }
    } catch (e) {
      console.log(e)
    }
  }
  
  return (
    <>
      <Styled.SLayoutDiv>
        <div className="row">
          <div className="sunstone-list large-12">
            <ListTable
              cols={columns}
              list={data?.circuitList}
              selectCallback={setRowEvt}
              deselectCallback={unsetRowEvt}
              title={"Circuit List"}
              selectRow={defaultValueIdx}
            />
          </div>
        </div>

        <div className={`sunstone-list ${circuitSelect ? " " : " hidden"}`}>
          <div className="row">
            <Styled.STitleDiv
              className={`${
                themeColor === "dark" ? " dark-theme-tc" : " "
              } large-12 columns`}
            >
              <span>Circuit Info</span>
            </Styled.STitleDiv>
          </div>

          <div className="row">
            <div
              className="large-12"
              style={{
                border: "1px solid #eee",
              }}
            >
              <YamlEditor
                theme={oneDark}
                ref={editorRef}
                onChange={()=>{}}
              />
            </div>
          </div>
        </div>

        <div
          className="row"
          style={{
            marginTop: "1rem",
          }}
        >
          <div className="sunstone-list large-12 columns ">
            {/* 우측 정렬 */}
            <div className="text-right">
              <button
                className="button"
                disabled={!circuitSelect}
                onClick={handleSelect}
              >
                {!data?.instrumentInfo?.circuit_id?"Select": data?.instrumentInfo?.circuit_id==circuitInfo?.id? "Select" : "Update" }
              </button>
            </div>
          </div>
        </div>
      </Styled.SLayoutDiv>
      {updatePop&&renderConfirmPop()}
    </>
  );
};

export default CircuitSelect;
